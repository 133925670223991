@import '../../assets/Variables.scss';

.id-card {
    font-size: 18px;
    border: 1px solid rgb(138, 138, 138);
    border-radius: 5px;
    height: 37vh;
    margin: 5%;
    overflow: hidden;
    box-shadow: 0px 0px 8px 4px #868686b0;

    .upper-section {
        height: 46%;
        background-color: rgb(199, 199, 199);
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .lower-section {
        height: 54%;
        position: relative;

        .address {
            height: 25%;
            padding: 4%;
            text-align: center;
        }

        .discription {
            background-color: rgba(233, 233, 233, 0.815);
            height: 50%;
            // padding: auto 3%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .upper,
            .lower {

                .amount,
                .amount-for {
                    text-align: center;
                }

            }
        }


        .view-button {
            font-size: 18px;
            border: none;
            background-color: black;
            color: white;
            border: 3px solid $accentRed;
            position: absolute;
            height: 30%;
            width: 100%;
            bottom: 0;
            right: 0;
        }
    }
}

@media (max-width:768px){
    .id-card{
        height: 53vh;
        width: 67vw;
    }
}