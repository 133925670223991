// @import '../../assets/Global.scss';
@import '../../assets/Variables.scss';


.accentstyles {
    display: block;
    color: #FFF;
    background: #000;
    background-clip: padding-box;
    $border: 3px;
    border: solid $border transparent;
    border-top: 1px;

    &:after {
        content: '';
        position: absolute;
        height: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        margin: -$border;
        /* !importanté */
        border-radius: inherit;
        /* !importanté */
        background-color: linear-gradient(1.0turn, #F94848 0%, rgba(0, 0, 0, 1) 100%);
    }
}

#navbar {
    // border: 2px solid black
    width: 100vw;
    list-style-type: none;
    height: $navbar-height;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-weight: 600;
    background-color: black;
    &:after {
        height: $navbar-height+1;
    }

    // stroke: linear-gradient(green, yellow);


    .nav-links-group {
        width: 50%;
        // border:2px solid green;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #navpage-links {
        margin: auto;
        justify-content: flex-start;
    }

    #account-links {
        justify-content: flex-end;
        margin: auto;
    }

    .nav-link {
        margin: auto 2%;
    }

    a {
        text-decoration: none;
        color: white;
    }


}

@media (max-width:768px) {}

//tablet design
@media (max-width:480px) {

    #navbar {
        // height: $navbar-height + 0.7vh;
        // min-height: $navbar-height;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        align-content: center;
        z-index: 99;

        #navpage-links,
        #account-links {
            transition: all 1s ease;
            border: 2px solid black;
            position: absolute;
            display: flex;
            height: $navbar-height + 40vh;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            z-index: -1;
            background-color: black;
            width: 101.8%;
            left: -1%;
            // display: none; //remove this line to make the links appear in the mobile mode
        }

        #navpage-links {
            top: $navbar-height + 1vh;
            // bottom: 0;
        }

        #account-links {
            top: ($navbar-height * 2) + 40vh;
            height: $navbar-height + 11vh;
            // display: none;
        }


    }


    .accentstyles {
        display: none;

        &::after {
            display: none;
        }
    }

}