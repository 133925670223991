#agencies {
    height: 89vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    #container {
        margin: 1% auto;
        height: 100%;
        width: 80%;
        overflow: scroll;
        border:2px solid black;
        &::-webkit-scrollbar{
            display: none;
        }

        #agency-list {
            border: none;
            height: 80%;
            width: 100%;
            overflow: scroll;
            background-color: black;
            position: relative;
            
            // #header{

            //     position: sticky;
            //     width: 100%;
            //     top:0;
            // }
            tr {
                
                height: 5vh;
                background-color: rgb(207, 207, 207);
                th {
                    height: 5vh;
                    max-height: 5vh;
                    color: white;
                    background-color: black;
                    //   height: 30%;  
                }
                
                td {
                    padding: 1%;
                    padding-left: 1%;
                }
            }
        }
    }
}

@media (max-width:768px){
    #agencies{
        #container{
            width: 100%;
            height: 100%;
            #agency-list{
                #header{
                    .branch, .email{
                        display: none;
                    }
                }
            }
        }
    }
}