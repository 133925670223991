#profile {
    margin: auto;
    width: 30vh;
    height: 40vh;
    min-width: 220px;
    min-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    background-image: url('../../assets/SVGs/profile.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;


    #author-image{
        width: 60%;
    }

    h1 {
        margin: auto;
        text-align: center;
    }

    #social-media-links {
        // margin: auto;
        padding: 0;
        height: 17%;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        align-items: center;


        li {
            margin: 10% auto;
            width: 10%;
            height: 100%;

            // border:2px solid black;
            // width: ;
            #linked-in:only-child,
            #github:only-child,
            #twitter:only-child {
                border: 2px solid white;
            }

        }

        a {
            color: white;
        }
    }
}

@media (max-width:768px) {
    #profile {
        padding: 5%;

        #profile-image {
            height: 140px;
            width: 140px;
        }
    }
}