@import '../../assets/Global.scss';

#forget-password{
    height: 94vh;
    width: 100%;
    
    #password-link-form{
        // border:2px solid black;
        width: 400px;
        height: 200px;
        flex-direction: column;
        justify-content: space-evenly;
        #submit-button{
            cursor: pointer;
            width: 20%;
            height: 20%;
            border:none;
            border-radius: 10%;
            background-color: rgb(6, 180, 6);
            &:active{
                background-color: rgb(1, 131, 1);
            }
        }
    }
}