@import '../../assets/Variables.scss';

#agency-area {
    width: 100%;
    height: 80vh;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
        width: 50%;
        text-align: center;
        padding: 4%;
        color: white;

        h1 {
            font-size: 4em;
        }

        p {
            padding: 4% 20%;
            margin: auto;
            font-size: 1.5em;
            width: 100%;
        }


        &::after {
            z-index: -1;
            // border: 2px solid black;
            content: "";
            position: absolute;
            height: 65%;
            width: 45%;
            left: 3%;
            top: 18%;
            background-image: url('../../assets/img/agency-section-background.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            filter: grayscale(80%);
            filter: blur(2px);
            // overflow: hidden;

        }
    }


    #right-triangle {
        display: block;
        content: "";
        padding: 0;
        width: 50%;
        height: 110%;
        background-image: url('../../assets/SVGs/righTriangle.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -2.2%;
        top: -5%;
        // bottom: -138%;
        // border: 2px solid black;


    }

}

@media (max-width: 768px) {

    #agency-area {
        height: auto;
        margin: 10% 0;

        .text {
            font-size: 0.7em;
            background-image: none;
            width: 100%;
            color: white;
            background-color: black;
            border: 7px solid $accentRed;
            border-left: none;
            border-right: 0;
            // position: relative;

            &::after {
                display: none;
                top: -15%;
                right: 0;
                border:2px solid black;
                width: 80%;
                filter: blur(1px);
            }
        }

        #right-triangle {
            border: 2px solid black;
            display: none;
        }
    }
}