@import '../../../assets/Global.scss';

section #user-info {
    border: 2px solid black;
    width: 30%;
    height: 63%;
    max-width: 450px;
    max-height: 600px;
    border-radius: 5px;

    #user-info-form {
        height: 100%;   

        justify-content: space-around;

        img {
            border: 2px solid black;
            height: 200px;
            width: 200px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
        #user-profile-holder{
            display: none;
        }
        .user-info-input {
            width: 70%;

        }
        button{
            border:1px solid black;
            &:hover{
                background-color:black ;
                color:white;
            }
        }
    }
}