@import '../../assets/Global.scss';

#user-profile {
    height: 93vh;
    width: 100vw;

    #user-profile-card {
        border: 2px solid rgba(145, 145, 145, 0.685);
        $height: 400px;
        width: 10%;
        height: 40%;
        min-height: $height;
        min-width: $height - 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        padding: auto;
        border-radius: 5px;

        #upper-portion {
            height: 60%;
            width: 100%;
            overflow: hidden;
            #dp-container{
                height: 200px;
                width: 200px;
                img {
                    border-radius: 50%;
                    height: 100%;
                    width: 100%;
                    border:1px solid black;
                    object-fit: cover;
                }
            }
        }

        #lower-portion {
            width: 100%;
            height: 40%;
            justify-content: space-evenly;

            #profile-button {
                width: 80%;
                height: 30%;
                flex-direction: row;
                justify-content: space-evenly;
                button:hover{
                    width: auto;
                    border-radius: 5px;
                    border:2px solid black;
                    // padding: 2%;
                    // font-size: 1rem;
                    // font-weight: 700;
                    color:white;
                    background-color: black;
                }
            }
        }
    }
}