div.profile-image {
    margin: 10% auto;
    margin-top: 15%;
    background-position: center;
    background-size: cover;
    border: 4px solid white;
    width: 60;
    height: 50%;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;

    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        // width: 100;
    }
}