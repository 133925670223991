@import '../../assets/Variables.scss';

#about-section {
    height: 80vh;
    position: relative;
    align-items: center;

    #section-name {
        padding: 0;
        margin: 0 auto;
        width: 100%;
        height: 10vh;
        overflow: hidden;

        #about {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            background-image: url('../../assets/SVGs/way2way.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            font-size: 2.5em;
            height: 100%;
            color: white;

        }

    }

    #sub-section {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        #about-text {
            margin: auto;
            text-align: center;
            color: white;
            font-size: 1.7rem;
            z-index: 1;
            width: 45%;
            background-image: url('../../assets/SVGs/aboutText.svg');
            padding: 8% 6%;
            padding-left: 8%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    // #about-svg {
    //     position: absolute;
    //     top: 30%;
    //     left: 27%;
    // }

}

@media (max-width:768px) {
    #about-section {
        margin-top: 10vh;
        height: 60vh;

        #sub-section {
            #about-text {
                width: 100%;
                background-image: none;
                background-color: black;
                border: 7px solid $accentRed;
                border-left: none;
                border-right: none;
            }
        }
    }
}