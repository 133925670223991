#post-ad {
    width: 100%;
    height: 94vh;

    // border:2px solid black;
    #post-ad-form {
        position: relative;
        // border: 2px solid black;
        width: 80%;
        height: 80%;
        padding: 1%;
        justify-content: space-evenly;
        $form-width: 35%;

        .input-field {
            width: $form-width;
        }

        .grouped-input-field {
            width: $form-width;
            flex-direction: row;
            justify-content: space-between;

            .input-field {
                width: 49%;
                // margin: auto;

            }
        }

        
        #upload-icon {
            // border:2px solid black;
            width: 60px;
            // padding: 10%;
            height: initial;

        }
    }

    #radio-form {
        width: 50%;
    }

    #radio-group,
    #radio-form {
        // border: 2px solid black;
        justify-content: space-evenly;
        flex-direction: row;
    }

    #ad-submit-button:hover{
        background-color: black;
        color:white;
    }
}