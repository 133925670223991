#accomodation {
    margin-top: 0.7vh;

    #container {
        margin: auto;
        max-width: 2080px;
        padding: 7%;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        // grid-template-rows: 20%;
    }
}


@media (max-width:768px) {

    #accomodation {
        #container {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }
    }
}