#sign-up {
    margin-top: 0.5vh;
    height: 93.5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    #sign-up-card {
        // border:2px solid black;
        margin: auto;
        font-family: 'Segoe UI', Geneva, Verdana, sans-serif;
        padding: 8% 6%;
        // padding-right: 2%;
        height: 90%;
        width: 35.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;


        #name {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        h1 {
            font-size: 4em;
            padding: 0;

        }

        #signup-button {
            width: 100%;
            height: auto;
            position: relative;
            display: flex;
            align-items: center;

            #input-signup-button {
                outline: none;
                cursor: pointer;
                position: absolute;
                float: right;
                right: 0%;
                width: 20%;
                min-width: 9ch;
                border: none;
                background-color: black;
                color: white;
                font-size: 1.5rem;
                padding: 1% 2%;
                border-radius: 37px;
                border-color: #F44646;
                border-width: 3px;
                border-style: solid;
            }
        }

        hr{
            visibility: hidden;
        }

        #alternative-signup-button {
            visibility: hidden;
            height: 5%;
            display: flex;
            align-items: center;

            .signup-button {
                width: 15%;
                height: 100%;
                margin-left: 3%;
                padding: 1%;
                border: none;
                background-color: white;
                border-radius: 37px;
                border-color: rgb(61, 61, 61);
                border-width: 1px;
                border-style: solid;

                img {
                    height: 100%;
                }
            }
        }
    }


    #signup-art {
        position: relative;
        width: 62.5%;
        height: 100%;
        padding: 0;

        p {
            position: absolute;
            font-size: 100px;
            width: 50%;
            overflow-wrap: normal;
            min-width: 10ch;
            text-align: right;
            // border: 2px solid white;
            top: 10%;
            right: 10%;
            color: white;
        }

        #art {
            content: "";
            top: 0;
            height: inherit;
            widows: inherit;
            background-image: url('../../assets/SVGs/login-art.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: inherit;
        }
    }
}

a {
    text-decoration: none;
    color: black;
}

@media (max-width:768px) {
    #sign-up {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        
        #sign-up-card {
            // height: 60%;
            padding: 0 5%;
            width: 100%;

            #signup-button {

                #input-signup-button {
                    width: 30%;
                }
            }

            #alternative-signup-button {
                .signup-button {
                    width: 20%;
                }
            }
        }

        #signup-art {
            display: none;
        }
    }
}