#login {
    // border: 2px solid black;
    margin-top: 0.5vh;
    height: 93.5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    #login-card {
        font-family: 'Segoe UI', Geneva, Verdana, sans-serif;
        padding: 5%;
        padding-right: 2%;
        height: 100%;
        width: 37.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        h1 {
            font-size: 4em;
            padding: 0;

        }

        #error{
            margin: 0;
            padding: 0;
            // border:2px solid black;
            color:red;

        }

        #login-options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .MuiCheckbox-colorPrimary {
                color: #F44646;
            }
        }

        #login-button {
            width: 100%;
            // border: 2px solid black;
            height: auto;
            position: relative;
            display: flex;
            align-items: center;

            #input-login-button {
                outline: none;
                cursor: pointer;
                position: absolute;
                float: right;
                right: 0%;
                width: 20%;
                border: none;
                background-color: black;
                color: white;
                font-size: 1.5rem;
                padding: 1% 2%;
                border-radius: 37px;
                border-color: #F44646;
                border-width: 3px;
                border-style: solid;
            }
        }

        hr{
            visibility: hidden; //remove these lines to show the buttons back again
        }

        #alternative-login-button {
            visibility: hidden; //remove these lines to show the buttons back again
            height: 5%;
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
            // border: 2px solid black;

            .login-button {
                width: 15%;
                height: 100%;
                // margin: auto;
                margin-left: 3%;
                padding: 1%;
                border: none;
                background-color: white;
                border-radius: 37px;
                border-color: rgb(61, 61, 61);
                border-width: 1px;
                border-style: solid;

                img {
                    height: 100%;
                }
            }
        }
    }


    #login-art {
        position: relative;
        width: 62.5%;
        height: 100%;
        padding: 0;

        p {
            position: absolute;
            font-size: 100px;
            width: 50%;
            overflow-wrap: break-word;
            min-width: 10ch;
            text-align: right;
            // border: 2px solid white;
            top: 10%;
            right: 10%;
            color: white;
        }

        #art {
            content: "";
            top: 0;
            height: inherit;
            widows: inherit;
            background-image: url('../../assets/SVGs/login-art.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: inherit;
        }
    }
}

a {
    text-decoration: none;
    color: black;
}

@media (max-width:768px) {
    #login {
        height: 92vh;

        #login-card {
            width: 100%;
            // margin:0 auto;
            padding: 0 5%;

            #login-button>#input-login-button {
                width: 30%;
            }

            #alternative-login-button>.login-button {
                width: 20%;
            }

        }

        #login-art {
            display: none;
        }

    }
}