@import '../../assets/Global.scss';
@import '../../assets/Variables.scss';

#footer {
    width: 100vw;
    min-height: 200px;
    border: 2px solid black;
    padding: 10px;
    background-color: black;
    border: none;
    border-top: 10px solid $accentRed;
    color: white;
    flex-direction: row;
    justify-content: space-evenly;

    h3 {
        font-size: 24px;
    }

    ul {
        min-width: 230px;
        // remove the default styling for te list
        list-style-type: none;
        width: 100px;

        .link{
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }

        li {
            // All the list headings in the footer willbe colored with accent color and margin
            margin: 5%;

            h3 {
                color: $accentRed;
                margin: 4% auto;
            }

            p {
                width: 100%;
                line-height: 23px;
                text-align: justify;
                word-break: normal;
            }

            a {
                color: white;
            }
        }
    }

    #author {
        &>ul {
            width: 100%;

            // li.display-as-flex {
            //     align-items: center;

            // }
        }

        .footer-author-dp {
            width: 100px;
            height: 100px;
            margin: 0;
            padding: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}

@media (max-width: 480px){
    #footer{
        flex-direction: column;
        ul{
            width: 90%;
            li{
                width: 100%;
            }
        }
    }
}